import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  index(idCliente : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/contratos/' + idCliente + '/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/contratos/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  create(idCliente : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/contratos/' + idCliente + '/create', Sessions.headerSession(this.objAuthService.access_token));
  }

  store(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/contratos", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  refresh( idContratoRegistroPrestacionServicio : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/contratos/" + idContratoRegistroPrestacionServicio + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  findClientes( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/contratos/" + strSearch + "/findClientes", Sessions.headerSession(this.objAuthService.access_token));
  }

  ordenPedidoFindByIdCliente( idCliente : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/contratos/" + idCliente + "/find_ordenes_pedidos", Sessions.headerSession(this.objAuthService.access_token));
  }

  documentPdf(idContratoRegistroPrestacionServicio : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/contratos/" + idContratoRegistroPrestacionServicio + "/pdf", Sessions.headerFile(this.objAuthService.access_token));
  }
}
