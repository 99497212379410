import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {NumeroOrdinalPipe} from "./numero-ordinal.pipe";



@NgModule({
  declarations: [
    NumeroOrdinalPipe
  ],
  imports: [
    CommonModule
  ],
  exports : [
    NumeroOrdinalPipe
  ]
})
export class NumeroOrdinalModule { }
