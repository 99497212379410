import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputAutocompleteComponent } from './input-autocomplete.component';



@NgModule({
  declarations: [
    InputAutocompleteComponent
  ],
  imports: [
    CommonModule
  ],
  exports : [
    InputAutocompleteComponent
  ]
})
export class InputAutocompleteModule { }
