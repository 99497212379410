import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload.component';
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    FileUploadComponent
  ],
      imports: [
            CommonModule,
            MatTooltipModule
      ],
  exports : [
    FileUploadComponent
  ]
})
export class FileUploadModule { }
