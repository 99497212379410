import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {PictureGridUploadComponent} from "./picture-grid-upload.component";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    PictureGridUploadComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule
  ],
  exports : [
    PictureGridUploadComponent
  ]
})
export class PictureGridUploadModule { }
