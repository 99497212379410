import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialesCategoriasService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  index(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias", Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable(idTutorialCategoria : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/tutoriales/categorias/' + idTutorialCategoria + '/datatable', Sessions.headerSession(this.objAuthService.access_token));
  }

  store( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  edit(idUnidadMarca : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias/" + idUnidadMarca + "/edit", Sessions.headerSession(this.objAuthService.access_token));
  }

  update( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  delete( idUnidadMarca : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias/" + idUnidadMarca + "/delete", Sessions.headerFormData(this.objAuthService.access_token));
  }

  deleteList(lstUnidadesMarcas : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias/deleteSelected", lstUnidadesMarcas, Sessions.headerSession(this.objAuthService.access_token));
  }

  searchVideo( objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias/search-video", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  misCursos( idUsuario : number ) : Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/categorias/" + idUsuario + "/mis-cursos", Sessions.headerSession(this.objAuthService.access_token));
  }

}
