import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../auth.service";
import {Observable} from "rxjs";
import {ApplicationConfig} from "../../libraries/application-config";
import {Sessions} from "../../libraries/sessions";

@Injectable({
  providedIn: 'root'
})
export class TutorialesVideosPreguntasService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {}

  datatable(objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + 'api/tutoriales/videos/preguntas/datatable', objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  preguntas( idTutorialVideo : number ) : Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/videos/preguntas/" + idTutorialVideo + "/datatable", Sessions.headerSession(this.objAuthService.access_token));
  }

  preguntasStore( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/videos/preguntas", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  respuestasStore( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/videos/preguntas/respuesta", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  preguntasDelete( idTutorialVideoPregunta : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/videos/preguntas/" + idTutorialVideoPregunta + "/delete", Sessions.headerFormData(this.objAuthService.access_token));
  }

  respuestasDelete( idTutorialVideoPreguntaRespuesta : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/tutoriales/videos/preguntas/respuesta/" + idTutorialVideoPreguntaRespuesta + "/delete", Sessions.headerFormData(this.objAuthService.access_token));
  }
}
