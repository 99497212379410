import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApplicationConfig} from "../../libraries/application-config";
import {Sessions} from "../../libraries/sessions";
import {map} from "rxjs/operators";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {AuthService} from "../auth.service";

@Injectable({
  providedIn: 'root'
})

export class MesaControlFicherosService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  index( idGerenciamientoLog : number ) : Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos-log/ficheros/" + idGerenciamientoLog , Sessions.headerSession(this.objAuthService.access_token));
  }

  store(objGerenciamientoFichero : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos-log/ficheros", objGerenciamientoFichero, { headers: Sessions.headerFormData(this.objAuthService.access_token).headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          let total : any = event?.total;
          const progress = Math.round(100 * event.loaded / total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

  download(idGerenciamientoLog : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/gerenciamientos-log/ficheros/" + idGerenciamientoLog + "/download", Sessions.headerFile(this.objAuthService.access_token));
  }

  zip(lstClientesFicherosSeleccionados : any): Observable<Blob> {
    return this.http.post<Blob>(ApplicationConfig.ENDPOINT + "api/gerenciamientos-log/ficheros/zip", lstClientesFicherosSeleccionados, Sessions.headerFile(this.objAuthService.access_token));
  }
}
